<template>
<v-navigation-drawer :rail="props.modelValue" class="!tw-border-0 tw-duration-200" :style="{backgroundImage: `url('${$frontend('images/bg.png')}')`, backgroundColor: isDark ? `rgb(3 7 18 / 0.7)` : '#127FBC', backgroundBlendMode: 'luminosity', boxShadow: `0.5px 0px 0px 0px ${isDark ? 'white' : '#127FBC'} !important`, backgroundPosition: 'center', backgroundSize: 'cover'}">
    <div class="tw-flex tw-flex-col tw-h-full">
        <div class="tw-w-full tw-p-3 tw-h-[64px]x tw-flex tw-items-center tw-justify-center tw-duration-200" :class="[props.modelValue && 'tw-pt-7x']">
            <img :src="$frontend('images/logo.svg')" class="tw-w-[100px] tw-h-[50px] tw-mx-auto">
        </div>
        
        <div class="tw-w-full tw-mt-[40px] tw-space-y-2">
            <button class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-bg-white tw-py-2 tw-px-4">
                <icon icon="solar:chart-2-bold" class="tw-text-2xl tw-text-[#127FBC] dark:tw-text-[#021c2a] " />
                <span v-if="!props.modelValue" class="tw-text-[#127FBC] dark:tw-text-[#021c2a] tw-text-sm tw-font-medium">Dashboard</span>
            </button>

            <button class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-py-2 tw-px-4 hover:tw-bg-white/10 tw-duration-200">
                <icon icon="solar:notebook-minimalistic-bold" class="tw-text-2xl tw-text-white " />
                <span v-if="!props.modelValue" class="tw-text-white tw-text-sm">Pages</span>
            </button>

            <button class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-py-2 tw-px-4 hover:tw-bg-white/10 tw-duration-200">
                <icon icon="solar:buildings-bold" class="tw-text-2xl tw-text-white " />
                <span v-if="!props.modelValue" class="tw-text-white tw-text-sm">Office</span>
            </button>

            <button class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-py-2 tw-px-4 hover:tw-bg-white/10 tw-duration-200">
                <icon icon="solar:users-group-two-rounded-bold" class="tw-text-2xl tw-text-white " />
                <span v-if="!props.modelValue" class="tw-text-white tw-text-sm">Members</span>
            </button>

            <button class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-py-2 tw-px-4 hover:tw-bg-white/10 tw-duration-200">
                <icon icon="fa6-solid:handshake-angle" class="tw-text-2xl tw-text-white " />
                <span v-if="!props.modelValue" class="tw-text-white tw-text-sm">Partners</span>
            </button>

            <button class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-py-2 tw-px-4 hover:tw-bg-white/10 tw-duration-200">
                <icon icon="solar:wallet-money-bold" class="tw-text-2xl tw-text-white " />
                <span v-if="!props.modelValue" class="tw-text-white tw-text-sm">Balance</span>
            </button>

            <button class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-py-2 tw-px-4 hover:tw-bg-white/10 tw-duration-200">
                <icon icon="solar:settings-bold" class="tw-text-2xl tw-text-white " />
                <span v-if="!props.modelValue" class="tw-text-white tw-text-sm">Settings</span>
            </button>
            
            
        </div>

        <div class="tw-flex-1"></div>

        <div class="tw-py-2">
            <button class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-py-2 tw-px-4 hover:tw-bg-white/10 tw-duration-200">
                <icon icon="solar:logout-2-bold" class="tw-text-2xl tw-text-white " />
                <span v-if="!props.modelValue" class="tw-text-white tw-text-sm">Logout</span>
            </button>
        </div>
    </div>
</v-navigation-drawer>
</template>

<script setup>
import { defineProps } from 'vue';
import { computed } from 'vue';
import { useAppStore } from "@/stores/app/index";

const props = defineProps(['modelValue']);
const appStore = useAppStore();
const isDark = computed(() => appStore.theme == 'dark')

</script>

<style>

</style>