import { defineStore } from 'pinia';

export const useAppStore = defineStore('appStore', {
    state: () => ({
        theme: 'light',
        drawer: true

    }),

    getters: {},

    actions: {
        setTheme(value) {
            document.body.classList.toggle('tw-dark', value == 'dark')
            this.theme = value;
        }
    }
});