<template>
    <div>
        <button @click="appStore.setTheme(isDark ? 'light' : 'dark')" class="tw-p-2 hover:tw-bg-[#127FBC]/10 dark:hover:tw-bg-black/10 tw-duration-200 tw-rounded">
            <icon :icon="isDark ? 'solar:sun-2-bold' : 'solar:moon-stars-bold'" class="tw-text-xl tw-text-[#127FBC] dark:tw-text-white" />
        </button>
    </div>
</template>

<script setup>
import { useAppStore } from "@/stores/app/index";
import { computed } from "vue";


const appStore = useAppStore();
const isDark = computed(() => appStore.theme == 'dark')

</script>

<style>

</style>