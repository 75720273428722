<template>
  <v-layout :full-height="true">
    

    <dashboard-layout-sidebar v-model="drawer" />

    <v-app-bar elevation="0" class="tw-duration-200" :style="{backgroundImage: `url('${$frontend('images/bg.png')}')`, backgroundColor: isDark ? `#032C43` : '#ffffff', backgroundBlendMode: 'luminosity', boxShadow: `0px .5px 0px 0px ${isDark ? 'white' : '#127FBC'} !important`, backgroundPosition: 'center', backgroundSize: 'cover'}">
      <template #title>
        <div class="tw-pl">
          <button @click="drawer = !drawer" class="tw-p-2 hover:tw-bg-[#127FBC]/10 dark:hover:tw-bg-black/10 tw-duration-200 tw-rounded">
            <icon icon="line-md:menu" class="tw-text-2xl dark:!tw-text-white tw-text-[#127FBC]" />
          </button>
        </div>
      </template>
      <template #append>
        <div>
          <theme-switcher />
        </div>
      </template>
    </v-app-bar>

    <v-main class="d-flex align-center justify-center tw-duration-200" :style="{backgroundImage: `url('${$frontend('images/bg.png')}')`, backgroundColor: isDark ? `#032C43` : '#ffffff', backgroundBlendMode: 'luminosity', backgroundPosition: 'center', backgroundSize: 'cover'}" >
      <router-view></router-view>
    </v-main>
  </v-layout>
</template>

<script setup>
import ThemeSwitcher from './partials/ThemeSwitcher';
import DashboardLayoutSidebar from './DashboardLayoutSidebar';
import { computed, ref } from 'vue';
import { useAppStore } from "@/stores/app/index";

const drawer = ref(true);
const appStore = useAppStore();
const isDark = computed(() => appStore.theme == 'dark')

</script>

<style>

</style>