<template>
  <div class="tw-h-full tw-w-full text-white">
    <div class="tw-p-5 tw-grid tw-grid-cols-3 tw-gap-5">
      <div class="tw-p-16 dark:tw-bg-gray-950/70 tw-bg-white tw-rounded tw-shadow-smx tw-border tw-border-solid tw-border-gray-300 dark:tw-border-gray-800"></div>
      <div class="tw-p-16 dark:tw-bg-gray-950/70 tw-bg-white tw-rounded tw-shadow-smx tw-border tw-border-solid tw-border-gray-300 dark:tw-border-gray-800"></div>
      <div class="tw-p-16 dark:tw-bg-gray-950/70 tw-bg-white tw-rounded tw-shadow-smx tw-border tw-border-solid tw-border-gray-300 dark:tw-border-gray-800"></div>
    </div>
    <h1 v-if="false" class="tw-text-xl tw-font-bold">Home</h1>

    <button v-if="false" @click="onClickHandler" class="tw-px-4 tw-py-2 tw-rounded-md tw-text-white tw-bg-purple-500">Say Hello</button>

  </div>
</template>

<script setup>
import useAlert from '@/composables/useAlert';
import { ref } from 'vue';
const id = ref(1);

const onClickHandler = () => {
  if(id.value == 1) {
    useAlert('Hello Ilyass', 'info');
  }

  if(id.value == 2) {
    useAlert('Hello Yassine', 'danger');
  }

  if(id.value == 3) {
    useAlert('Hello Ahmed', 'warning');
  }

  if(id.value == 4) {
    useAlert('Hello Ali', 'success');
  }

  if (id.value == 4) {
    id.value = 1
  } else {
    id.value++
  }
}
</script>